





























































































































































































































































































































































































import {
    Component,
    Vue
} from 'vue-property-decorator';
import {
    getComponent
} from '@/utils/helpers';
import wellTestModule from '@/store/modules/wellTestModule';
import accountModule from '@/store/modules/accountModule';
import assetsModule from '@/store/modules/assetsModule';
import scheduleModule from '@/store/modules/scheduleModule';
import {
    addNumberPadding,
    sleep,
    dateDiff,
    getOffset,
    getElementPosition,
    getElementCenterPosition,
} from '@/utils/helpers'
import {
    SCHEDULE_COMPONENT_COLORS,
    SCHEDULE_DATE_TITLE_HEIGHT,
    SCHEDULE_CHART_DAY_WIDTH,
    SCHEDULE_ROW_HEIGHT,
    SCHEDULE_PIXELS_TO_CURRENT_DAY
} from '@/lib/constants'

@Component({
    components: {
        AppLayout: () => getComponent('common/AppLayout'),
        RigSchedule: () => getComponent('schedule/RigSchedule'),
    },
})

export default class WellTestSchedule extends Vue {
	// Loading views
    dataLoading = false
	loadingGanttChart = false
    isDeletingRowLoadingView = false
	dataLoadingDeleteRow = false

	// Gantt chart data
    viewTotalDays = 30
	dates: any[] = []
    currentDate = ''
    rows: any[] = []
	showingRowEditMenu = null
	deleteRigRowConfirmShowing = null

	// Add new row data
    addNewRowPopupShowing = false;
    newRowName = ''
    newRowLastTestedDate = ''
    addNewRowTesters: any[] = []
    addNewRowTesterIDCount = 0
    addNewRowTestFrequency = 30
    addNewRowTasqDuration = 24
    addNewRowSelectedWells: any[] = []


	ganttChartID = ''
	scheduleComponentUserColors = {}
	chartStartDatetime
	rowIDs: string[] = []
	isResizingElement = false
	didDragElement = false


	filterScheduledRowComponents: any[] = []


	toggleShowingRowEditMenu(selection_id) {
		if (this.showingRowEditMenu == selection_id) {
			this.showingRowEditMenu = null
		} else {
			this.showingRowEditMenu = selection_id
		}
		
	}

	resizeComponent(component_id, component) {
		let component_div = document.getElementById(component_id)!

		this.isResizingElement = true
		let m_pos;
		function resize(e) {
			const dx = (m_pos - e.x) * -1;
			m_pos = e.x;
			component_div.style.width = (parseInt(getComputedStyle(component_div, '').width) + dx) + "px";
		}


		
		document.addEventListener("mousemove", resize, false);
		document.addEventListener("mouseup", () => {
			document.removeEventListener("mousemove", resize, false);
			this.isResizingElement = false
			var roundedDayNumber = Math.round(parseInt(component_div.style.width) / SCHEDULE_CHART_DAY_WIDTH)
			component_div.style.width = (roundedDayNumber * SCHEDULE_CHART_DAY_WIDTH).toString() + "px"
			this.updateTestDurationHours(component, roundedDayNumber * 24)
		}, false);
	}




				// // Allow user to resize the component
                // var expand_right_div = document.createElement("div")
				// expand_right_div.classList.add("expand-right-div")
                // expand_right_div.onmousedown = function (component_div, component) {
                //     return function () {
                //         isResizingElement = true
                //         let m_pos;
                //         function resize(e) {
                //             const dx = (m_pos - e.x) * -1;
                //             m_pos = e.x;
                //             component_div.style.width = (parseInt(getComputedStyle(component_div, '').width) + dx) + "px";
                //         }
                //         document.addEventListener("mousemove", resize, false);
                //         document.addEventListener("mouseup", function () {
                //             document.removeEventListener("mousemove", resize, false);
                //             __isResizingElement = false
                //             var roundedDayNumber = Math.round(parseInt(component_div.style.width) / SCHEDULE_CHART_DAY_WIDTH)
                //             component_div.style.width = (roundedDayNumber * SCHEDULE_CHART_DAY_WIDTH).toString() + "px"
                //             updateTestDurationHours(component, roundedDayNumber * 24)
                //         }, false);
                //     }
                // }(component_div, component);







	getComponentLeftPosition(component) {
		let days_diff = dateDiff(this.chartStartDatetime, new Date(component.start_date))
		return (days_diff * SCHEDULE_CHART_DAY_WIDTH).toString() + "px"
	}

	getComponentWidth(component) {
		var width_days_diff = dateDiff(new Date(component.start_date), new Date(component.end_date))
		return (width_days_diff * SCHEDULE_CHART_DAY_WIDTH).toString() + "px"
	}




    get enabledWells() {
        return assetsModule.enabledWells;
    }

    get currentDay(): number {
        return new Date().getDate()
    }

    get scheduledRowComponents(): any[] {
        return scheduleModule.scheduledRowComponents
    }


    async created() {
        assetsModule.getEnabledWells();
        this.reloadPage()
    }








	async reloadPage() {

		this.loadingGanttChart = true
		this.dates = []



		await scheduleModule.getGanttChartIDs()
		// if (scheduleModule.ganttChartIDs.length == 0) {
		// 	scheduleModule.postGantt({
		// 		chart_name: "Well Test Schedule"
		// 	})
		// 	await scheduleModule.getGanttChartIDs()
		// }
		this.ganttChartID = scheduleModule.ganttChartIDs[0].GanttChartID
		scheduleModule.resetScheduledRowComponents()

		await accountModule.getReassignmentList()
		await scheduleModule.getGantt({
			gantt_chart_id: this.ganttChartID
		})

	this.addDataToGanttChart()

	this.loadingGanttChart = false


}


	async addDataToGanttChart() {

		
		this.dates = []
		this.filterScheduledRowComponents = []

		this.currentDate = addNumberPadding((new Date().getMonth() + 1), 2, '0') + "/" + addNumberPadding((new Date().getDate()), 2, '0') + "/" + new Date().getFullYear()
		var start_datetime = new Date(new Date().setHours(new Date().getHours() - 24))
		start_datetime.setHours(0,0,0,0);
		this.chartStartDatetime = start_datetime
		var end_datetime = new Date(new Date().setHours(start_datetime.getHours() + (this.viewTotalDays * 24)))
		end_datetime.setHours(0,0,0,0);

		// Show chart 30 days
		for (var days = 0; days < this.viewTotalDays; days++) {
			var day_datetime = new Date(start_datetime)
			day_datetime = new Date(day_datetime.setDate(start_datetime.getDate() + days))
			var hours_array: Date[] = []
			var day_object = {
				"day_id": day_datetime.toString(),
				"day_short": day_datetime.getDate(),
				"day": day_datetime,
				"hours": hours_array
			}
			for (var hours = 0; hours < 24; hours++) {
				var hour_datetime = new Date(day_datetime)
				hour_datetime = new Date(hour_datetime.setHours(day_datetime.getHours() + hours))
				day_object.hours.push(hour_datetime)
			}
			this.dates.push(day_object)
		}

		await scheduleModule.getGanttChartIDs()
		// if (scheduleModule.ganttChartIDs.length == 0) {
		// 	scheduleModule.postGantt({
		// 		chart_name: "Well Test Schedule"
		// 	})
		// 	await scheduleModule.getGanttChartIDs()
		// }
		this.ganttChartID = scheduleModule.ganttChartIDs[0].GanttChartID
		scheduleModule.resetScheduledRowComponents()


		
		for (var x = 0; x < this.scheduledRowComponents.length; x++) {
			var rowElementID = this.scheduledRowComponents[x].title_row.selection_id.toLowerCase()
			rowElementID = rowElementID.replaceAll(" ", "")

			this.filterScheduledRowComponents.push(this.scheduledRowComponents[x])
		}


	}





    addNewRowRemoveTester(key) {
        for (var x = 0; x < this.addNewRowTesters.length; x++) {
            if (this.addNewRowTesters[x].key == key) {
                this.addNewRowTesters.splice(x, 1);
                break
            }
        }

    }

	

    hideAddNewRowPopup() {
        this.addNewRowPopupShowing = false
        this.addNewRowTesters = []
        this.addNewRowTesterIDCount = 0
    }

    addNewRowClicked() {
        this.addNewRowPopupShowing = true
    }


    async confirmDeleteRigRow() {
		this.dataLoadingDeleteRow = true

		scheduleModule.deleteWellTestGroup({
			gantt_chart_id: this.ganttChartID,
			// @ts-ignore
			group_structure: this.deleteRigRowConfirmShowing.title_row.selection_name
		})
		this.deleteRigRowConfirmShowing = null
        this.isDeletingRowLoadingView = false
		this.dataLoadingDeleteRow = false
        location.reload();
    }

	


  clostConfirmDeleteRigPopup() {
	  this.deleteRigRowConfirmShowing = null
  }

	showDeleteRowPopup(row) {
		this.deleteRigRowConfirmShowing = row
	}

	// 	scheduleModule.deleteWellTestGroup({
	// 		gantt_chart_id: this.ganttChartID,
	// 		group_structure: row.title_row.selection_name
	// 	})

    //     this.isDeletingRowLoadingView = false
    //     location.reload();
    // }

    async submitNewRow() {

        var testers: any[] = []
        var newTestersClass = document.getElementsByClassName("newTestersClass");
        for (var x = 0; x < newTestersClass.length; x++) {
            // @ts-ignore
            testers.push(newTestersClass[x].value)

        }

        await scheduleModule.getGanttChartIDs()
        this.dataLoading = true

        // Post groups
        for (var x = 0; x < testers.length; x++) {
            var payload = {
                gantt_chart_id: scheduleModule.ganttChartIDs[0].GanttChartID,
                group_structure: this.newRowName + "." + testers[x]
            }
            // await scheduleModule.postGroup(payload)
        }

        var lastTestedDateList: any[] = []
        for (var v = 0; v < this.addNewRowSelectedWells.length; v++) {
            lastTestedDateList.push(this.newRowLastTestedDate)
        }

		// Post clusters
        // await scheduleModule.postCluster({
        //     GanttChartID: scheduleModule.ganttChartIDs[0].GanttChartID,
        //     GroupMapPath: this.newRowName,
		// 	DefaultDurationHours: this.addNewRowTasqDuration,
        //     NodeIDs: this.addNewRowSelectedWells,
        //     LastDateCompleted: lastTestedDateList,
        //     ClusterParams: JSON.stringify({
        //         "DaysFreq": this.addNewRowTestFrequency,
        //         "TaskDurationHours": this.addNewRowTasqDuration
        //     })
        // })

        this.dataLoading = false
        this.hideAddNewRowPopup()
        this.reloadPage()

    }







	// Pulled in from outside



getComponentDateRangeFromChartLocation(component) {
    var target_element_pos = getElementPosition(component, "DRAGGED")
    var target_element_min_x = target_element_pos.x
    var target_element_max_x = target_element_pos.x + component.getBoundingClientRect().width

    var new_start_of_day = (Math.floor((target_element_min_x - SCHEDULE_PIXELS_TO_CURRENT_DAY) / SCHEDULE_CHART_DAY_WIDTH) + this.chartStartDatetime.getDate())
    var new_end_of_day = (Math.floor((target_element_max_x - SCHEDULE_PIXELS_TO_CURRENT_DAY) / SCHEDULE_CHART_DAY_WIDTH) + this.chartStartDatetime.getDate())
    var firstDayStart = new Date(this.chartStartDatetime.getFullYear(), this.chartStartDatetime.getMonth(), 1);
    var firstDayEnd = new Date(this.chartStartDatetime.getFullYear(), this.chartStartDatetime.getMonth(), 1);
    var new_start_date = firstDayStart.setDate((firstDayStart.getDate()) + new_start_of_day);
    var new_end_date = firstDayEnd.setDate((firstDayEnd.getDate()) + new_end_of_day);
    return [new Date(new_start_date), new Date(new_end_date)]
}

getClosestRowIDOnDragEnd(element) {
    var elementCenter = getElementCenterPosition(element, "DRAGGED")

	for (var x = 0; x < this.filterScheduledRowComponents.length; x++) {
		let filterScheduledRowComponent = this.filterScheduledRowComponents[x]
		for (var y = 0; y < filterScheduledRowComponent.rows.length; y++) {
			let row = filterScheduledRowComponent.rows[y]
			let rowID = filterScheduledRowComponent.title_row.selection_name + row.title_row.selection_id
			var rowElement = document.getElementById(rowID) !
				var rowMinY = getElementCenterPosition(rowElement, "ROW").y - ((rowElement.getBoundingClientRect().height / 2) + 35)
			var rowMaxY = getElementCenterPosition(rowElement, "ROW").y + ((rowElement.getBoundingClientRect().height / 2) + 115)

			if (elementCenter.y > rowMinY && elementCenter.y < rowMaxY) {
				return rowID
			} else if (elementCenter.y <= rowMinY && x == 0) {
				return rowID
			} else if (elementCenter.y >= rowMaxY && x == (this.rowIDs.length - 1)) {
				return rowID
			}
		}
	}
}

getAllChartComponents(ignoringDraggedComponent: any = null) {
    var totalComponentsList = document.querySelectorAll('[isDraggableComponent="true"]')
    if (ignoringDraggedComponent != null) {
        var otherComponents: any[] = []
        for (var x = 0; x < totalComponentsList.length; x++) {

            if (ignoringDraggedComponent!.getAttribute('componentID') == totalComponentsList[x].getAttribute('componentID')) {
                continue
            }
            otherComponents.push(totalComponentsList[x])
        }
        return otherComponents
    }
    return totalComponentsList
}


getColorForComponent(component) {
	var color = "#51cea8"
	if (this.scheduleComponentUserColors[component.assignee] != null) {
		color = this.scheduleComponentUserColors[component.assignee]
	} else {
		color = SCHEDULE_COMPONENT_COLORS[Object.keys(this.scheduleComponentUserColors).length % SCHEDULE_COMPONENT_COLORS.length]
		this.scheduleComponentUserColors[component.assignee] = color
	}
	return color
}





// ***********************************************
// ********** Update component params ************
// ***********************************************

async updateTestDurationHours(component, hours) {
    // document.getElementById("savingDraggedComponentID") !.style.display = "block"
    // await scheduleModule.putTasksParams({
    //     GanttChartID: this.ganttChartID,
    //     TaskID: component.component_id,
    //     ModifyObject: "CurrentDurationHours",
    //     Value: hours
    // })
    // document.getElementById("savingDraggedComponentID") !.style.display = "none"
    // this.addDataToGanttChart()
}
/*
  
*/
async scootFlagSelected(component) {
    // document.getElementById("savingDraggedComponentID") !.style.display = "block"
    // await scheduleModule.scootFlagSelected({
    //     task_id: component.component_id,
    //     gantt_chart_id: this.ganttChartID,
    //     cluster_id: component.assignee
    // })
    // location.reload()
}


updateComponentRanges(componentsToUpdate) {
    // document.getElementById("savingDraggedComponentID") !.style.display = "block"
    // var promises: any[] = [];
    // for (var y = 0; y < componentsToUpdate.length; y++) {
    //     var prediction_id = componentsToUpdate[y].PredictionID
    //     var start_date = componentsToUpdate[y].StartDate

    //     var startDateString = start_date.getUTCFullYear() + "-" + addNumberPadding(start_date.getUTCMonth() + 1, 2, '0') + "-" + addNumberPadding(start_date.getUTCDate(), 2, '0')

    //     promises.push(scheduleModule.putTasksParams({
    //         GanttChartID: this.ganttChartID,
    //         TaskID: prediction_id,
    //         ModifyObject: "TargetDate",
    //         Value: startDateString
    //     }))

    // }

    // Promise.all(promises).then(() => {
	// 	document.getElementById("savingDraggedComponentID") !.style.display = "none"
	// 	// this.reloadPage()
    //     // location.reload()
	// 	this.addDataToGanttChart()
    // }, function (err) {
    //     // error occurred
    // });

}



/* 
   ***********************************************
   ********* Handle dragged components ***********
   ***********************************************
*/

pos1 = 0
pos2 = 0
pos3 = 0
pos4 = 0
draggedElement


startDrag(e, component) {
	this.dragMouseDown(e)
}


dragMouseDown(e) {
	e = e || window.event;
	e.preventDefault();
	var target_element = e.target
	if (!e.target.hasAttribute('isDraggableComponent')) {
		target_element = e.target.parentElement
	}
	this.draggedElement = target_element
	// get the mouse cursor position at startup:
	target_element.style.zIndex = "99999"
	var components = this.getAllChartComponents(target_element)

	for (var x = 0; x < components.length; x++) {
		components[x].style.zIndex = "999"
		components[x].style.setProperty("z-index", "999", "important");
	}

	this.pos3 = e.clientX;
	this.pos4 = e.clientY;
	document.onmouseup = this.closeDragElement;
	// call a function whenever the cursor moves:
	document.onmousemove = this.elementDrag;
}

elementDrag(e) {
	if (this.isResizingElement) {
		return
	}
	e = e || window.event;
	e.preventDefault();
	// calculate the new cursor position:
	this.pos1 = this.pos3 - e.clientX;
	this.pos2 = this.pos4 - e.clientY;
	this.pos3 = e.clientX;
	this.pos4 = e.clientY;
	// set the element's new position:
	this.draggedElement.style.top = (this.draggedElement.offsetTop - this.pos2) + "px";
	this.draggedElement.style.left = (this.draggedElement.offsetLeft - this.pos1) + "px";
	this.didDragElement = true
}

closeDragElement(e) {
    /* stop moving when mouse button is released:*/
    document.onmouseup = null;
    document.onmousemove = null;
    if (!this.didDragElement) {
        return
    }
    var components = this.getAllChartComponents(e.target)

    for (var x = 0; x < components.length; x++) {
        components[x].style.zIndex = "99999"
    }
	this.didDragElement = false
    this.handleDraggedComponent(e)

}




snapToClosestDayXValue(target_element) {
    var target_element_pos = getElementPosition(target_element, "DRAGGED")
    var target_element_min_x = target_element_pos.x

    var closest_day = (Math.floor(((target_element_min_x - (SCHEDULE_CHART_DAY_WIDTH / 1.5)) - SCHEDULE_PIXELS_TO_CURRENT_DAY) / SCHEDULE_CHART_DAY_WIDTH) + this.chartStartDatetime.getDate()) + 1
    target_element.style.left = ((closest_day * SCHEDULE_CHART_DAY_WIDTH) - ((this.chartStartDatetime.getDate() - 1) * SCHEDULE_CHART_DAY_WIDTH)) + "px"
}

snapDraggedElementToRow(target_element, draggedElementNewRowID) {
    var componentRowID = draggedElementNewRowID
    target_element.style.top = "10px"
    document.getElementById(componentRowID)!.appendChild(target_element)
    this.snapToClosestDayXValue(target_element)

}

handleDraggedComponent(e) {

    var target_element = e.target
    if (!e.target.hasAttribute('isDraggableComponent')) {
        target_element = e.target.parentElement
    }
    if (!target_element.hasAttribute('isDraggableComponent')) {
        target_element = target_element.parentElement
    }

    var draggedElementNewRowID = this.getClosestRowIDOnDragEnd(target_element)
    var otherComponents: any[] = []
    var totalComponentsList = document.querySelectorAll('[isDraggableComponent="true"]')
    for (var x = 0; x < totalComponentsList.length; x++) {
        if (target_element.getAttribute('componentID') == totalComponentsList[x].getAttribute('componentID')) {
            continue
        }
        if (draggedElementNewRowID == this.getClosestRowIDOnDragEnd(totalComponentsList[x])) {
            otherComponents.push(totalComponentsList[x])
        }
    }

    this.snapDraggedElementToRow(target_element, draggedElementNewRowID)
    var componentsToUpdate: any[] = []
    var newTargetElementDateRange = this.getComponentDateRangeFromChartLocation(target_element)
    componentsToUpdate.push({
        "PredictionID": target_element.getAttribute('componentID'),
        "StartDate": newTargetElementDateRange[0],
        "EndDate": newTargetElementDateRange[1],
        "RowID": draggedElementNewRowID
    })
    if (otherComponents.length > 0) {

        // Overlaps and preceeding
        var overlap_proceeding: any[] = []
        var non_overlap_proceeding: any[] = []
        var overlap_following: any[] = []
        var non_overlap_following: any[] = []

        var target_element_pos = getElementPosition(target_element, "DRAGGED")
        var target_element_center_x = getElementCenterPosition(target_element, "DRAGGED").x
        var target_element_max_x = target_element_pos.x + target_element.getBoundingClientRect().width
        var target_element_min_x = target_element_pos.x
        for (var y = 0; y < otherComponents.length; y++) {
            var other_component_pos = getElementPosition(otherComponents[y], "OTHER")
            var other_element_center_x = getElementCenterPosition(otherComponents[y], "OTHER").x
            var other_element_max_x = other_component_pos.x + otherComponents[y].getBoundingClientRect().width
            var other_element_min_x = other_component_pos.x

            if (other_element_center_x < target_element_center_x && other_element_max_x > target_element_min_x) {
                overlap_proceeding.push(otherComponents[y])
            } else if (other_element_center_x > target_element_center_x && other_element_min_x < target_element_max_x) {
                overlap_following.push(otherComponents[y])
            } else if (other_element_center_x < target_element_center_x) {
                non_overlap_proceeding.push(otherComponents[y])
            } else if (other_element_center_x > target_element_center_x) {
                non_overlap_following.push(otherComponents[y])
            } else if (other_element_min_x < target_element_max_x && other_element_max_x > target_element_min_x) {
                overlap_following.push(otherComponents[y])
            }
        }
    }
    this.updateComponentRanges(componentsToUpdate)
}





}





